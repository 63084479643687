import "./App.css";
import React from "react";
import QRScanner from "./QRScanner";

function App() {
  const [scannedData, setScannedData] = React.useState(null);

  React.useEffect(() => {
    if (scannedData) {
      console.log("sendData", scannedData);
      const base64PaymentScan = new Buffer(scannedData).toString("base64");
      console.log("base64PaymentScan", base64PaymentScan);
    }
  }, [scannedData]);
  return (
    <div className="App">
      <header className="App-header">
        <p>Barcode Detector</p>
        <QRScanner setScannedData={setScannedData} />
      </header>
    </div>
  );
}

export default App;
