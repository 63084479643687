import React from "react";
import WebcamDecoder from "./WebcamDecoder";

const QRScanner = ({ setScannedData: passScannedData }) => {
  const [cam, setCam] = React.useState(false);
  const [scannedData, setScannedData] = React.useState(null);

  React.useMemo(() => passScannedData(scannedData), [scannedData]);

  return (
    <div>
      {cam ? (
        <WebcamDecoder
          constraints={{ audio: false, video: { facingMode: "environment" } }}
          captureSize={{ width: 1280, height: 720 }}
          onDecode={(codes) =>
            codes[0] !== scannedData && setScannedData(codes[0])
          }
        />
      ) : (
        <button onClick={() => setCam(true)}>start camera</button>
      )}
    </div>
  );
};

export default QRScanner;
