import React from "react";
import "./polyfill/barcodeDetector";

const barcodeDetector = new window.BarcodeDetector({ formats: ["qr_code"] });
let lastDecodeThrottle = 0;
let mediaStream = null;

const Video = ({
  className = "",
  onDecode,
  constraints = {},
  captureSize = { width: 1280, height: 720 },
  decodeThrottle = 500,
}) => {
  const [init, setInit] = React.useState(false);
  const artboardRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const webcamRef = React.useRef(null);
  const constraintsRef = React.useRef(constraints);

  React.useEffect(() => {
    if (
      (init && constraintsRef.current === constraints.current) ||
      !webcamRef.current
    ) {
      return;
    }

    constraintsRef.current = constraints;
    setInit(true);
    startUp();
    renderFrame();
    return () =>
      mediaStream && mediaStream.getTracks().forEach((track) => track.stop());
  }, [webcamRef, constraints]);

  const initCamera = () =>
    new Promise((resolve) => {
      mediaStream && mediaStream.getTracks().forEach((track) => track.stop());

      const video = webcamRef.current;

      // get video stream
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((newMediaStream) => {
          mediaStream = newMediaStream;
          video.srcObject = mediaStream;
          resolve(video);
        })
        .catch((e) => console.log("ERROR", e));
    });

  const startUp = () => initCamera().then((video) => video.play());

  const calculateSize = (srcSize, dstSize) => {
    const srcRatio = srcSize.width / srcSize.height;
    const dstRatio = dstSize.width / dstSize.height;

    if (dstRatio < srcRatio) {
      return {
        width: dstSize.height * srcRatio,
        height: dstSize.height,
      };
    } else {
      return {
        width: dstSize.width,
        height: dstSize.width / srcRatio,
      };
    }
  };

  const renderFrame = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const video = webcamRef.current;
    // re-register callback
    requestAnimationFrame(renderFrame);
    // set internal canvas size to match HTML element size
    canvas.width = canvas.scrollWidth;
    canvas.height = canvas.scrollHeight;
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      const now = new Date().getTime();
      if (now - decodeThrottle >= lastDecodeThrottle) {
        barcodeDetector
          .detect(video)
          .then((barcodes) => {
            console.log("barcodeDetector success", barcodes);
            barcodes.length && onDecode(barcodes.map((code) => code.rawValue));
          })
          .catch((err) => {
            console.error("barcodeDetector error", err);
          });
        lastDecodeThrottle = now;
      }
      // scale and horizontally center the camera image
      const videoSize = { width: video.videoWidth, height: video.videoHeight };
      const canvasSize = { width: canvas.width, height: canvas.height };
      const renderSize = calculateSize(videoSize, canvasSize);
      const yOffset = (canvasSize.height - renderSize.height) / 2;
      const xOffset = (canvasSize.width - renderSize.width) / 2;
      context.drawImage(
        video,
        xOffset,
        yOffset,
        renderSize.width,
        renderSize.height
      );
    }
  };

  return (
    <div ref={artboardRef}>
      <video
        ref={webcamRef}
        playsInline
        width={captureSize.width}
        height={captureSize.height}
        style={{ display: "none" }}
      />
      <canvas ref={canvasRef} style={{ width: 1280, height: 720 }} />
    </div>
  );
};
export default Video;
